//
//
//
//
//
//

export default {
  async asyncData({ $storyblok, store, params }) {
    const slug = !params.slug || params.slug == 'index.html' ? '' : params.slug
    const page = await $storyblok.getPage(`pages/${slug}`)
    store.commit('setPage', page)
    // if (process.env.DEV) {
      // console.log(page)
    // }
    return {
      page: page,
    }
  },
  head() {
    return this.$metadata(this.page)
  }
}
