//
//
//
//
//
//

 

export default {
  props: [
    'content'
  ],
 
}
